@import '../../assets/styles/mixins';

.title {
  @include Title_H2;
  margin-bottom: 3.125rem;
}

.services {
  margin: 2rem 0 7.120rem;
}

.bread_crumbs {
  margin: 3.125rem 0 2.5rem;
}

.search_form {
  margin-bottom: 3.125rem;
}

.card {
  margin-bottom: 1.875rem;
}
