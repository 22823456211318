@import '../../../../assets/styles/mixins';

.list {
  //max-width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .title {
    align-items: center;
    color: textColor('darck-grey');
    display: flex;
    font-family: $font-base;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 10px;
  }

  .link {
    line-height: 18px;
    //margin-left: 0;
    display: inline-block;
    margin: 2px 0;
  }
}

@media screen and (max-width: 970px) {
  .list {
    margin-bottom: 15px;
  }
}