@import '../../../assets/styles/mixins';

.cover {
  background: shapeColor('blue') url('../../../assets/images/home_page/bg-header.png') no-repeat bottom right 32%;
  background-size: auto;
}

.header {
  margin: 6rem 0;

  &_title {
    @include Title_H1(textColor('white'));
  }

  &_paragraph {
    @include Paragraph(textColor('white'), $fontWeight: 600);
    margin: 40px 0 60px;
  }
}
// media запросы

@media screen and (max-width: 1023px) {
  .cover {
    background: shapeColor('blue');
    text-align: center;
  }
}

@media screen and (max-width: 570px) {
  .header {
    margin: 4rem 0;
    &_title {
      font-size: 30px;
      line-height: 35px;
    }
    &_paragraph {
      margin: 40px 0;
    }
  }
}
