@import "../../../assets/styles/mixins";

%base_button {
  border-radius: 5px;
  display: inline-block;
  line-height: 24px;
  font: {
    family: $font-base;
    weight: 700;
    size: 14px;
  };
  transition: .8s;
}

.primary_button {
  @extend %base_button;
  border: 1px solid shapeColor('blue');
  background-color: shapeColor('blue');
  height: 3.375rem;
  color: textColor('white');
  padding: 0.875rem 5.4375rem;

  &:hover,
  &:active {
    color: textColor('white');
    background-color: shapeColor('blue');
    transition: 0s;
  }
}

.secondary_button {
  @extend %base_button;
  border-radius: 2px;
  border: 1px solid shapeColor('light-grey');
  background-color: shapeColor('light-grey');
  height: 2.75rem;
  line-height: 22px;
  font: {
    weight: 600;
    size: 14px;
  }
  color: textColor('blue');
  padding: 10px 30px;

  &:hover,
  &:active {
    border-color: #eaeaea;
    background-color: #eaeaea;
    color: textColor('blue');
    transition: 0s;
  }
}
