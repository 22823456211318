@import '../../../assets/styles/mixins';

.wrapper {
  margin-bottom: 7.5rem;
  margin-top: 5rem;
}

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.8125rem;
}

.title {
  @include Title_H2;
}

.link {
  color: textColor('darck-grey');
  font-weight: 700;
}

.card {
  height: 200px;
  display: flex;
  overflow: hidden;
  margin-bottom: 20px;
  position: relative;

  &:after {
    content: '';
    background-color: #1c1f2380;
    width: 100%;
    height: 100%;
    z-index: 1;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
  }

  a {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    z-index: 2;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
  }

  &_title {
    @include Title_H3;
    color: #fff;
    height: 43px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0.625rem; // 10px
  }

  &_description {
    overflow: hidden;
    height: 80px;
    @include Paragraph($lineHeight: 1.625rem); // 26px
    color: #fff;
  }

  &_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
