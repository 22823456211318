@import '../../../assets/styles/mixins';

.title_h1 {
  @include Title_H2(textColor('blue'));
  margin: 4rem 0;
  font-size: 1.5rem;
}

.left_content {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1.6875rem 1.8rem;
  background: shapeColor('light-grey');
  border-radius: 5px;

  .title_h3 {
    @include Title_H3(textColor('blue'));
    margin: 1rem 0 1.5rem;
  }

  .paragraph {
    @include Paragraph(textColor('grey'));
  }

  .icon {
    max-width: 9.8125rem;
  }
}

.right_content {
  align-items: center;
  border-radius: 5px;
  display: flex;
  justify-content: center;

  .image {
    max-height: 21.25rem;
    width: 100%;
  }
}

// media запросы

@media screen and (max-width: 1399px) {
  .right_content {
    margin-top: 25px;
  }
}

@media screen and (max-width: 800px) {
  .left_content {
    align-items: center;
  }
  .right_content {
    .image {
      display: none;
    }
  }
}
