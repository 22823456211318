@import '../../assets/styles/mixins';

.title {
    @include Title_H2(textColor('blue'));
    margin: 30px 0 20px;
  }
  
  .small_title {
    @include Title_H3(textColor('blue'));
    margin: 30px 0 20px;
  }
  
  .paragraph {
    @include Paragraph(textColor('darck-grey'), $fontWeight: 600);
    margin-bottom: 20px;
  
    span {
      font-weight: 700;
    }
  }
  
  .link {
    border-radius: 3px;
    display: inline-block;
    background-color: shapeColor('blue');
    padding: 10px 20px;
    color: textColor('white');
    margin-right: 10px;
  
    &:hover {
      background-color: shapeColor('blue-sky');
      color: textColor('white');
    }
  }
  
  .table {
    margin: 20px 0 0;
  
    tbody tr:last-child {
      border-width: 0;
    }
  
    tr {
      border-bottom: 1px solid #e1e1e1;
  
      th {
        font-weight: 700;
      }
  
      td {
        @include Paragraph(textColor('darck-grey'), $fontWeight: 600);
      }
    }
  }
  
  .user_cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 40px;
  
    .user_card {
      max-width: 210px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
  
      img {
        border-radius: 50%;
        display: inline-block;
        object-fit: cover;
      }
    }
  }
  
  // media запросы
  
  @media screen and (max-width: 445px) {
    .user_cards {
      justify-content: center;
    }
  }