@import "../../../assets/styles/mixins";

.label {
  //max-width: 28.125rem; // 450px
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.required {
  color: #BE0D0D;
}

.text {
  color: #57565C;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

.input {
  border-radius: 5px;
  border: 1px solid #E0E0E0;
  background-color: shapeColor('light-grey');
  height: 3.125rem;
  color: #57565C;
  padding: 0.625rem 1.25rem;

  &::placeholder {
    font: {
      family: $font-base;
      size: 14px;
    };
    color: #737373;
  }
}
