@import '../../../assets/styles/mixins';

.navbar {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap !important;
  padding-bottom: 0 !important;
  padding-top: 1.125rem !important;

  &_container {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .dropdown {
    display: flex;
    align-items: center;
    margin-left: 1.125rem;
  }

  .top_bar {
    display: flex;
    max-width: 1200px;
    width: 100%;
  }

  .bottom_bar {
    border-top: 1px solid #eaeaea;
    margin-top: 1rem;
    width: 100%;
    position: relative;
  }

  .link {
    align-items: center;
    color: textColor('darck-grey');
    display: flex;
    font-family: $font-base;
    font-size: 14px;
    margin-left: 1.125rem;
    cursor: pointer;

    &_active {
      color: textColor('sky');
    }

    svg {
      margin-right: 3px;
    }

    &:hover {
      color: textColor('sky');
    }

    &:first-child {
      margin-left: 0;
    }
  }

  .menu {
    justify-content: space-between;
    margin: 1.125rem auto 1rem;
    max-width: 1200px;
    width: 100%;
  }

  .submenu {
    background-color: #fff;
    width: 100%;
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.2);
    z-index: 1001;
    position: absolute;
    right: 0;
    left: 0;

    .container {
      width: 100%;
      max-width: 1200px;
      display: flex;
      justify-content: space-between;
      margin: 0 auto 1rem;
      flex-wrap: wrap;
    }
  }
}

.logo {
  margin-right: 1.875rem;
}

@media screen and (max-width: 1200px) {
  .navbar {
    .menu {
      max-width: unset;
      overflow-x: scroll;
      overflow-y: hidden;
      ::before {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        left: -10px;
        width: 18px;
        height: 70px;
        opacity: .2;
        background: linear-gradient(90deg, #31353b, transparent);
      }
      ::after {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        right: -1px;
        width: 18px;
        height: 70px;
        opacity: .2;
        background: linear-gradient(-90deg, #31353b, transparent);
      }
    }  
  }
}

@media screen and (max-width: 970px) {
  .navbar {
    .bottom_bar {
      padding: 0 10px;
    }
    .link {
      display: inline;
    }
    .submenu {
      padding: 0 10px;
      .container {
        justify-content: flex-start;

      }
    }
    .menu {
      ::after {
        content: "";
        right: -10px;
      }
    }
  }
}