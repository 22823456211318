@import '../../../assets/styles/mixins';

.wrapper {
  background-color: shapeColor('light-grey');
  margin: 5.9375rem 0;
  padding: 5rem 0;
}

.title {
  @include Title_H2(textColor('blue'));
  margin: 0 0 2.5rem 0;
}

.left_content {
  background: shapeColor('blue-sky');
  border-radius: 0.3125rem;
  display: flex;

  &_title {
    @include Title_H2(textColor('white'));
    margin-bottom: 10px;
    margin-top: 3.8125rem;
  }

  &_paragraph {
    @include Paragraph(textColor('light-grey'));
  }
}

.right_content {
  background: shapeColor('blue-sky');
  border-radius: 5px;
  min-height: 14rem;
  padding: 3.75rem 0 0 2.5rem ;

  &_title {
    @include Title_H2(textColor('white'));
    margin-bottom: 10px;
  }

  &_paragraph {
    @include Paragraph(textColor('light-grey'))
  }
}

.footer {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 0.9375rem;

  .paragraph {
    @include Paragraph(textColor('darck-grey'))
  }

  .button {
    background: shapeColor('blue-sky');
    border-color: shapeColor('blue-sky');
    border-radius: 5px;
    text-align: center;
    transition: background .2s linear;
    width: 19.0625rem;
  }
}

// media запросы

@media screen and (max-width: 1399px) {
  .left_content {
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 709px) {
  .footer {
    .button {
      width: 14rem;
      padding: 0.875rem 1rem;
    }
  }
}

@media screen and (max-width: 630px) {
  .left_content {
    display: none;
  }
  .right_content {
    padding: 3.75rem;
    text-align: center;
  }
  .footer {
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    .paragraph {
      text-align: center;
    }
  }
}
