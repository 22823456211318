@import '../../assets/styles/mixins';

.description {
  @include Paragraph;
}

.title_1 {
  @include Title_H1;
}

.title_2 {
  @include Title_H2;
}

.title_3 {
  @include Title_H3;
}
