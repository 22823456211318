@import '../../../../assets/styles/mixins';

.title {
  @include Title_H3(textColor('blue'));
  margin: 30px 0 20px;
}

.list {
  list-style: outside;
  padding-left: 20px;

  li {
    @include Paragraph(textColor('darck-grey'), $fontWeight: 600);
    margin: 5px 0;

    &:last-child,
    &:first-child {
      margin: 0;
    }
  }
}
