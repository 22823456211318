@import '../../assets/styles/mixins';

.wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 80px auto 300px;
  max-width: 470px;

  .logo {
    margin-bottom: 50px;
  }

  .content {
    border: 1px solid transparent;
    border-radius: 5px;
    box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.25);
    min-height: 580px;
    padding: 40px 40px 80px;
    width: 100%;

    .title {
      @include Title_H2(textColor('blue'));
      margin-bottom: 3.125rem;
      text-align: center;
    }

    .address {
      margin-top: 30px;

      &_row {
        display: flex;
        justify-content: space-between;
      }

      &_label {
        max-width: 31.43%;
      }
    }

    .personal_dates {
      align-items: center;
      display: flex;
      margin: 60px 0 20px;

      .checkbox {
        background-color: shapeColor('light-grey');
        border-color: #E5E5E5;
        border-radius: 5px;
        height: 27px;
        width: 26px;
      }

      &_label {
        @include Paragraph($fontSize: 0.8125rem);
        color: #898890;
        margin-left: 0.6875rem;
      }
    }

    .button {
      width: 100%;
      height: 3.125rem;
    }
  }
}
