.search_form {
  display: flex;
  justify-content: space-between;

  input {
    border-color: #E0E0E0;
    border-radius: 5px 0 0 5px;
    color: #8B8B8B;
    text-transform: uppercase;

    &:placeholder-shown {
      text-transform: inherit;
    }
  }
}

.label {
  max-width: 100%;
  width: 100%;
}

.button {
  border-radius: 0 5px 5px 0;
  width: 3.4375rem;
}
