@import '../../../assets/styles/mixins';

.description_content {
  background-color: #1c1f2380;
  padding: 10px;
}

.slider_title {
  @include Title_H3;
  margin-bottom: 20px;
  color: #fff;
}

.slider_description {
  @include Paragraph($lineHeight: 1.62rem, $fontSize: 15px); // 26px
  color: #fff;
}