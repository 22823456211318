@import "../../../assets/styles/mixins";

%base_button {
  border-radius: 5px;
  display: inline-block;
  line-height: 1.625rem;
  font: {
    family: $font-base;
    weight: 700;
    size: 1rem;
  };
}

.icon_button {
  @extend %base_button;
  border: 1px solid shapeColor('sky');
  background-color: shapeColor('sky');
  height: 3.125rem;
  color: textColor('white');
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1.125rem;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
