@import "variables";

/* ============= Параграф ============= */

@mixin Paragraph($color: textColor('grey'), $fontSize: 14px, $fontWeight: 400, $lineHeight: 20px) {
  line-height: $lineHeight;
  font: {
    family: $font-base;
    weight: $fontWeight;
    style: normal;
    size: $fontSize;
  }
  color: $color;
}

/* ============= Заголовки ============= */

@mixin Title_H1($color: textColor('blue'), $lineHeight: 48px) {
  line-height: $lineHeight;
  font: {
    family: $font-base;
    weight: 700;
    size: 40px;
  }
  color: $color;
}

@mixin Title_H2($color: textColor('blue'), $lineHeight: 30px) {
  line-height: $lineHeight;
  font: {
    family: $font-base;
    weight: 700;
    size: 24px;
  }
  color: $color;
}

@mixin Title_H3($color: textColor('blue'), $fontWeight: 700) {
  font: {
    family: $font-base;
    weight: $fontWeight;
    size: 1.25rem; // 20px
  }
  color: $color;
}
