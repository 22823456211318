@import "../../../assets/styles/mixins";

.card {
  border-radius: 5px;
  border: 1px solid #B8B8B8;
  height: 9.375rem;
  max-height: 9.375rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: .8s;
  padding: 0.9375rem 0.9375rem 1.25rem 1.25rem;

  &:hover {
    border: 1px solid shapeColor('sky-blue');
    background-color: shapeColor('light-grey');
    cursor: pointer;
    transition: 0s;

    .title,
    .paragraph {
      color: textColor('blue');
    }
  }
}

.title {
  font: {
    family: $font-base;
    weight: 700;
    size: 1rem;
  }
  color: #57565C;
}

.paragraph {
  font: {
    family: $font-base;
    weight: 500;
    size: 0.75rem;
  }
  color: #898890;
  margin-top: 0.5rem;
}

.icon {
  max-width: 4.375rem;
  max-height: 4.375rem;
  margin-left: auto;
}
