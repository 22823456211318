$font-base: 'Open Sans', sans-serif;

/* ============= Переменные цветов ============= */

$shapesColors: (
  'blue': #0D54BE,
  'blue-sky': #1069EE,
  'sky': #1795FF,
  'light-grey': #F7F9FC,
  'alternative-grey': #CACACA,
  'darck-grey': #373737,
  'dark-blue': #252B42,
);

$textColor: (
  'blue': #0D54BE,
  'blue-sky': #1069EE,
  'sky': #1795FF,
  'white': #fff,
  'light-grey': #F9F9FF,
  'alternative-grey': #CACACA,
  'grey': #737373,
  'darck-grey': #373737,
);

/* ============= Функции для выбора цвета ============= */

@function shapeColor($key) {
  @return map-get($map: $shapesColors, $key: $key);
}

@function textColor($key) {
  @return map-get($map: $textColor, $key: $key);
}
