@import '../../../assets/styles/mixins';

.wrapper {
  //background-color: shapeColor('light-grey');
  //margin: 5.9375rem 0;
  padding-top: 5rem;
}

.title {
  @include Title_H2;
}

.chart_image {
  margin: 1.4rem 0;
  width: 100%;
}

.chart_description {
  @include Paragraph;
}

.iphone_image {
  margin-bottom: 0.9375rem;
}

.search_form label {
  margin-bottom: 6px;
}

.example {
  @include Paragraph;
  padding-left: 1.25rem;
}

.description {
  @include Paragraph;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-left: 1.25rem;
}

.content {
  margin-left: auto;
}

.buttons {
  display: flex;
  justify-content: space-between;
}

.tracking {
  width: calc(100% - 60px);
  position: relative;
  margin: 140px auto 0;
}

.progress_line {
  border-radius: 20px;
  background-color: #E0E0E0;
  height: 10px;

  div {
    border-radius: 20px;
    background-color: #1795FF;
    height: 10px;
    display: flex;
    justify-content: space-between;
    position: relative;

    .item {
      border-radius: 50%;
      background-color: #1795FF;
      width: 21.42px;
      height: 20.25px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      position: relative;
      top: -5px;

      &:before,
      &:after {
        content: '';
        background-color: #fff;
        width: 1px;
        height: 9px;
        display: block;
        margin: 0 1px;
      }

      &:last-child .item_content {
        background-color: #1DC0FF;

        &:after {
          background-color: #1DC0FF;
        }
      }

      &_content {
        border-radius: 4px;
        background-color: #BDBDBD;
        min-width: 140px;
        height: 60px;
        font-weight: 600;
        font-size: 12px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        padding: 10px;
        position: absolute;
        top: -70px;

        &:after {
          content: "";
          background-color: #BDBDBD;
          width: 10px;
          height: 10px;
          display: block;
          transform: rotate(45deg);
          position: absolute;
          bottom: -5px;
        }
      }
    }
  }
}

// медиа запросы

@media screen and(max-width: 991px) {
  .title {
    margin-bottom: 10px;
  }
}

@media screen and(max-width: 932px) {
  .tracking {
    width: calc(100% - 115px);
  }
}

@media screen and (max-width: 710px) {
  .tracking_wrapper {
    overflow-y: auto;
  }
  .tracking {
    width: 600px;
    margin: 140px 60px 13px;
  }
}