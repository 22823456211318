@import '../../../../assets/styles/mixins';

.form_container {
  box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.25);
  height: 46.8125rem;
  margin: 3.125rem 0;
  padding: 2.9375rem 9rem 5.25rem 9.0625rem;
  width: 61.25rem;
}

.title {
  @include Title_H2();
  margin-bottom: 3.375rem;
}

.label {
  @include Paragraph();
  color: #373737;
  font-weight: 600;
  margin-top: 2.625rem;
}

.input {
  background-color: #F7F9FC;
  border: 1px solid #E0E0E0;
  border-radius: 5px;
  color: #8B8B8B;
}

.input_second {
  background-color: #F7F9FC;
  border: 1px solid #E0E0E0;
  border-radius: 5px;
  color: #8B8B8B;
}

.input_content {
  @include Paragraph();
  color: #373737;
  margin-top: -0.9375rem;
}

.textarea {
  background-color: #F7F9FC;
  border: 1px solid #E0E0E0;
  border-radius: 5px;
  height: 5.5625rem;
  margin-top: 46px;
  @include Paragraph(textColor('dark-grey'));
  outline: none;
  padding-left: 22px;
  padding-top: 19px;
  resize: none;
  width: 43.1875rem;
}

.form_footer {
  margin-bottom: 33px;
  margin-top: 16px;
}

.form_footer span {
  @include Paragraph();
  color: #373737;
  font-weight: 400;
}

.total_title {
  @include Paragraph();
  color: #373737;
  display: flex;
  font-weight: 600;
  justify-content: space-between;
  max-width: 306px;
}

.button {
  height: 54px;
  width: 19.8125rem;
}

.string {
  @include Paragraph();
  align-items: center;
  color: #373737;
  display: flex;
  font-weight: 400;
  margin-top: 20px;
}

.link {
  color: #1795FF;
  margin-left: 5px;
}

.checkbox {
  background-color: #F7F9FC;
  border: 1px solid #E0E0E0;
  border-radius: 5px;
  height: 27px;
  margin-right: 10px;
  width: 26px;
}
