@import '../../../assets/styles/mixins';

.content {
  margin: 0 auto;
  max-width: 445px;
  width: 100%;
}
e
.check {
  box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.25);
  height: 483px;
  margin-bottom: 30px;
  width: 445px;
}

.logo {
  margin: 38px 140px 0;
}

.icon {
  margin: 52px 195px 0;
}

.info {
  margin-top: 35px;
  padding: 0 30px;

  li {
    display: flex;
    justify-content: space-between;

    span {
      color: #373737;
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  max-width: 445px;
  padding: 30px 30px 0;

  .link {
    color: #1795FF;
  }
}

.button {
  background-color: #1069EE;
  margin: 0 0 50px 335px;
}
