@import '../../../../assets/styles/mixins';

.form {
  box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.25);
  height: auto;
  padding: 5rem 0 4.9375rem 9.0625rem;
  width: 61.25rem;
}

.title {
  color: textColor('blue');
  font: {
    family: $font-base;
    size: 1.5rem;
    weight: 700;
  }
  margin-bottom: 4.4375rem;
}

.input {
  background: #F7F9FC;
  border: 0.8px solid #E0E0E0;
  border-radius: 5px;
  height: 3.1875rem;
  margin-bottom: 1.875rem;
  width: 43.1875rem;
}

.button {
  background: #1069EE;
  border-radius: 4px;
  height: 3.375rem;
  width: 19.8125rem;
}

.paragraph {
  color: #373737;
  font-size: 0.875rem;
  margin-top: 5px;
}

.link {
  color: #1795FF;
}
