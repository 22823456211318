@import '../../../../assets/styles/mixins';

.link_button {
  color: textColor('gray');
  transition: .5s;
  cursor: pointer;

  &:hover {
    color: textColor('sky');
    transition: 0s;
  }
}

.disabled {
  cursor: not-allowed;
  pointer-events: none;
}
