@import '../../assets/styles/mixins';

.form_container {
  box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.25);
  padding: 2.9375rem 2rem 5.25rem 2rem;
  width: 100%;
}

.title {
  @include Title_H2();
  margin-bottom: 3.375rem;
}

.label {
  @include Paragraph();
  color: #373737;
  font-weight: 600;
  margin-top: 2.625rem;
}

.input {
  width: 100%;
  background-color: #F7F9FC;
  border: 1px solid #E0E0E0;
  border-radius: 5px;
  color: #8B8B8B;
}

.input_second {
  background-color: #F7F9FC;
  border: 1px solid #E0E0E0;
  border-radius: 5px;
  color: #8B8B8B;
}

.input_content {
  @include Paragraph();
  color: #373737;
  margin-top: -0.9375rem;
}

.textarea {
  background-color: #F7F9FC;
  border: 1px solid #E0E0E0;
  border-radius: 5px;
  height: 5.5625rem;
  margin-top: 46px;
  @include Paragraph(textColor('dark-grey'));
  outline: none;
  padding-left: 22px;
  padding-top: 19px;
  resize: none;
  width: 100%;
}

.form_footer {
  margin-bottom: 33px;
  margin-top: 16px;
}

.form_footer span {
  @include Paragraph();
  color: #373737;
  font-weight: 400;
}

.total_title {
  @include Paragraph();
  color: #373737;
  display: flex;
  font-weight: 600;
  justify-content: space-between;
  max-width: 100%;
}

.button {
  height: 54px;
  width: 100%;
}

.string {
  @include Paragraph();
  align-items: center;
  color: #373737;
  display: flex;
  font-weight: 400;
  margin-top: 20px;
}

.link {
  color: #1795FF;
  margin-left: 5px;
}

.checkbox {
  background-color: #F7F9FC;
  border: 1px solid #E0E0E0;
  border-radius: 5px;
  height: 27px;
  margin-right: 10px;
  width: 26px;
}



.label {
  //max-width: 28.125rem; // 450px
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.required {
  color: #BE0D0D;
}

.text {
  color: #57565C;
  font-size: 0.875rem;
  display: inline-block;
  margin-bottom: 0.5rem;
}

.input {
  border-radius: 5px;
  border: 1px solid #E0E0E0;
  background-color: shapeColor('light-grey');
  height: 3.125rem;
  color: #57565C;
  padding: 0.625rem 1.25rem;

  &::placeholder {
    font: {
      family: $font-base;
      size: 14px;
    };
    color: #737373;
  }
}

