@import '../../assets/styles/mixins';

.tracking_wrapper {
  position: relative;
  padding-left: 70px;
  margin: 100px auto;
  overflow: hidden;
}

.tracking {
  border-radius: 20px;
  background-color: #1795FF;
  width: 7px;
  position: absolute;
  bottom: 0;
  left: 108px;
  top: 0;
}

.item {
  display: flex;
  line-height: 18px;
  margin: 12px 0 12px -80px;

  &_date {
    width: 120px;
    height: 40px;
    text-align: center;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &:before {
      content: '';
      border-radius: 10px;
      border: 3px solid #FFF;
      width: 7px;
      height: 7px;
      position: absolute;
      top: 18px;
      right: -5px;
      bottom: 0;
    }
  }

  &_title {
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 7px;
    padding-left: 10px;
  }
}

//.progress_line {
//  border-radius: 20px;
//  background-color: #1795FF;
//  width: 7px;
//  height: 100px;
//
//  div {
//    border-radius: 20px;
//    background-color: #1795FF;
//    height: 10px;
//    display: flex;
//    justify-content: space-between;
//    position: relative;
//
//    .item {
//      border-radius: 50%;
//      background-color: #1795FF;
//      width: 21.42px;
//      height: 20.25px;
//      display: flex;
//      align-items: center;
//      justify-content: center;
//      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
//      position: relative;
//      top: -5px;
//
//      &:before,
//      &:after {
//        content: '';
//        background-color: #fff;
//        width: 1px;
//        height: 9px;
//        display: block;
//        margin: 0 1px;
//      }
//
//      &:last-child .item_content {
//        background-color: #1DC0FF;
//
//        &:after {
//          background-color: #1DC0FF;
//        }
//      }
//
//      &_content {
//        border-radius: 4px;
//        background-color: #BDBDBD;
//        min-width: 140px;
//        height: 60px;
//        font-weight: 600;
//        font-size: 12px;
//        text-align: center;
//        display: flex;
//        align-items: center;
//        justify-content: center;
//        color: #fff;
//        padding: 10px;
//        position: absolute;
//        top: -70px;
//
//        &:after {
//          content: "";
//          background-color: #BDBDBD;
//          width: 10px;
//          height: 10px;
//          display: block;
//          transform: rotate(45deg);
//          position: absolute;
//          bottom: -5px;
//        }
//      }
//    }
//  }
//}
//
//// медиа запросы
//
//@media screen and(max-width: 991px) {
//  .title {
//    margin-bottom: 10px;
//  }
//}
//
//@media screen and(max-width: 932px) {
//  .tracking {
//    width: calc(100% - 115px);
//  }
//}
//
//@media screen and (max-width: 710px) {
//  .tracking_wrapper {
//    overflow-y: auto;
//  }
//  .tracking {
//    width: 600px;
//    margin: 140px 60px 13px;
//  }
//}