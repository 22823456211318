@import '../../../assets/styles/mixins';

.mail {
  background-color: shapeColor('light-grey');
  overflow: hidden;
  padding-top: 1.875rem;
}

.title {
  @include Title_H2(textColor('blue'));
  margin: 3rem 0 2.1875rem;
}

.content {
  @include Paragraph(textColor('darck-grey'));
  margin-bottom: 20px;

  span {
    font: {
      size: 16px;
      weight: 600;
    }
  }
}

.qr {
  align-items: center;
  display: flex;

  &_img {
    margin-right: 10px;
  }

  &_paragraph {
    @include Paragraph(textColor('darck-grey'));
  }
}

.hand_img {
  margin-bottom: -0.2rem;
  max-width: 21.875rem;
}

.link {
  color: textColor('blue');
}

//media запросы

@media screen and (max-width: 1024px) {
  .hand_img {
    display: none;
  }
  .mail {
    text-align: center;
  }
  .qr {
    justify-content: center;
    margin-bottom: 40px;
  }
}