@import '../../assets/styles/mixins';

.wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 80px auto 300px;
  max-width: 470px;

  .logo {
    margin-bottom: 50px;
  }

  .content {
    border: 1px solid transparent;
    border-radius: 5px;
    box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.25);
    min-height: 520px;
    padding: 40px;
    width: 100%;

    .title {
      @include Title_H2(textColor('blue'));
      margin-bottom: 3.125rem;
      text-align: center;
    }

  }

  .button {
    margin: 24px 0 40px;
    width: 100%;
    height: 3.125rem;
  }

  .footer {
    align-items: center;
    display: flex;
    flex-direction: column;

    &_link {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }

  .down_link {
    font-size: 14px;
    margin-top: 30px;

    a {
      color: textColor('blue');
    }
  }
}
