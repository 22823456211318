@import '../../assets/styles/mixins';

.cover {
  background: shapeColor('blue') url('../../assets/images/profile/img-profile.png') no-repeat bottom right 8rem;
  background-size: auto;
}

.header {
  margin: 6rem 0;

  &_title {
    @include Title_H1(textColor('white'));
    text-transform: uppercase;
  }

  &_paragraph {
    @include Paragraph(textColor('white'));
    margin: 2.5rem 0;
  }
}

.profile_content {
  display: flex;
  justify-content: space-between;
}

.title {
  color: #0D54BE;
  font: {
    family: 'Raleway', sans-serif;
    size: 1.5rem;
    weight: 800;
  }
  margin-bottom: 3.8125rem;
  margin-top: 5.8125rem;
  text-transform: uppercase;
}

.label {
  @include Paragraph()
}

.input {
  margin-bottom: 1.0625rem;
  margin-top: 1rem;
}

.input_last {
  margin-bottom: 1.6875rem;
}

.input_home {
  height: 3.125rem;
  margin-right: 0.8125rem;
  width: 8.875rem;
}

.input_entrance {
  height: 3.125rem;
  margin-right: 0.8125rem;
  width: 8.875rem;
}

.input_flat {
  height: 3.125rem;
  width: 8.875rem;
}

.address_inputs {
  display: flex;
  margin-bottom: 1.6875rem;
}

.textarea {
  background: shapeColor('light-grey');
  border: 1px solid #E6E6E6;
  border-radius: 5px;
  color: #737373;
  font-size: 0.8125rem;
  height: 11.125rem;
  outline: none;
  padding-left: 1.25rem;
  padding-top: 1.125rem;
  resize: none;
  width: 28.3125rem;
}

.paragraph {
  color: #8B8B8B;
  font-family: 'Raleway', sans-serif;
  font-size: 0.875rem;
  margin-bottom: 5.5625rem;
  margin-top: 0.875rem;
}

.select {
  appearance: none;
  background-color: #F7F9FC;
  border: 1px solid #E0E0E0;
  border-radius: 5px;
  color: #737373;
  font-family: 'Raleway', sans-serif;
  font-size: 0.8125rem;
  height: 3.125rem;
  margin-top: 21px;
  padding-left: 1.125rem;
  text-decoration: none;
  width: 28rem;
}

.second_paragraph {
  color: #131313;
  font-family: 'Raleway', sans-serif;
  font-size: 0.875rem;
  margin-top: 0.6875rem;
}

.search {
  margin-top: 0.75rem;
}

.right_side {
  margin-left: 75px;
}

.text {
  color: #373737;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 15px;
  margin-top: 50px;
}

.button {
  background-color: #1069EE;
  height: 49px;
  width: 371px;
}
