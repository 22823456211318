@import '../../../assets/styles/mixins';

.wrapper {
  background-color: shapeColor('dark-blue');
  min-height: 33.5rem; // 530px
  padding-bottom: 2.375rem;
  padding-top: 4.4375rem;
}

.content {
  display: flex;
  flex-direction: column;
}

.title {
  @include Title_H3(textColor('white'));
  font-weight: 600;
  margin-bottom: 1.125rem;
}

.link {
  color: #FFFFFF;
  font: {
    family: $font-base;
    size: 0.875rem;
    weight: 400;
  }
  line-height: 1.4;
}

.sub_title {
  color: #FFFFFF;
  font: {
    family: $font-base;
    size: 1rem;
    weight: 600;
  }
  margin-bottom: 1.125rem;
  margin-top: 2.3125rem;
}

.info {
  color: #FFFFFF;
  font: {
    family: $font-base;
    size: 0.875rem;
    weight: 400;
  }
  line-height: 1.4;

  &_number {
    color: #FFFFFF;
    font: {
      family: $font-base;
      size: 0.875rem;
      weight: 400;
    }
    line-height: 1.4;
    margin-top: 1.9375rem;
  }
}

.copyright {
  color: #8B8B8B;
  font: {
    family: $font-base;
    size: 0.875rem;
    weight: 400;
  }
  margin-top: 2.9375rem;
  text-align: center;
}

// media запросы

@media screen and (max-width: 767px) {
  .title {
    margin-top: 40px
  }
}

@media screen and (max-width: 362px) {
  .info {
    &_number {
      white-space: nowrap;
    }
  }
}
